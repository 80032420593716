<template>
    <div class="i-surveys-form-input">
        <form-item v-bind="$attrs">
            <van-field
                v-model="modelValue"
                :placeholder="placeholder"
                readonly
                clickable
                :name="itemKey"
                :rules="rules"
                @click="clickOpen"
            />
        </form-item>

        <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
                v-model="currentDate"
                :type="getDateFormatType"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="handleConfirm"
                @cancel="handleCancel"
            />
        </van-popup>
        <van-popup v-model="showYearPicker" position="bottom">
          <van-picker
            :default-index="currentYearIndex"
            show-toolbar
            :columns="getColumns"
            @confirm="handleConfirm"
            @cancel="handleCancel"
          />
        </van-popup>
    </div>
</template>

<script>
import { max } from 'moment';
import FormItem from './form-item.vue'

export default {
    components: { FormItem },
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rules: {
            type: Array,
            default: () => ([])
        },
        dateFormat: {
            type: String,
            default: 'yyyy-MM-dd'
        },
        itemKey: {
            type: String,
            default: ''
        },
        timeSetting: {
          type: Object,
          default: () => ({})
        },
        timeLimitSetting: {
          type: Object,
          default: () => ({})
        },
        exam: {
          type: Array,
          default: () => ([])
        },
        timeDependSetting: {
          type: Object,
          default: () => ({})
        }
    },
    data: () => ({
        showPicker: false,
        showYearPicker: false,
        currentDate: new Date()
    }),
    computed: {
        modelValue: {
            get () {
                return this.value
            },
            set (val) {
                this.$emit('input', val)
            }
        },
        currentYearIndex () {
          const index = this.getColumns.indexOf(new Date().getFullYear());
          return index > -1 ? index : 0
        },
        getColumns () {
          const currentYear = new Date().getFullYear();
          if (this.dateFormat === 'yyyy') {
            if (this.timeSetting && this.timeSetting.timeRestrictions) {
              const minYear = Number(this.timeSetting.minTime.split('-')[0]);
              const maxYear = Number(this.timeSetting.maxTime.split('-')[0]);
              const columns = [minYear];
              while (columns[columns.length - 1] < maxYear) {
                columns.push(columns[columns.length - 1] + 1);
              }
              return columns;
            }
            return [currentYear]
          }
          return [currentYear]
        },
        minDate () {
          let dateVal = this.getTimeSettingVal('minTime')
          dateVal = dateVal || this.$moment().subtract(100, 'year').format('YYYY/MM/DD')
          return new Date(dateVal)
        },
        maxDate () {
          let dateVal = this.getTimeSettingVal('maxTime')
          dateVal = dateVal || this.$moment().subtract(-100, 'year').format('YYYY/MM/DD')
          return new Date(dateVal)
        },
        getDateFormatType () {
            if (this.dateFormat === 'yyyy-MM-dd HH:mm') {
                return 'datetime'
            }
            return 'date'
        },
        isDisabled () {
            return this.$route.query.formType === 'preview'
        }
    },
    methods: {
      getTimeSettingVal (key) {
        if (key && this.timeSetting && this.timeSetting.timeRestrictions) {
          if (this.timeSetting[key]) {
            return this.timeSetting[key].split('-').join('/');
          }
        }
        return null
      },
        clickOpen () {
            if (this.isDisabled) return
            if (this.dateFormat === 'yyyy') {
              this.showYearPicker = true
              return;
            }
            this.showPicker = true
        },
        timeDependSettingVertify (val) {
          if (this.timeDependSetting && this.timeDependSetting.timeRestrictions) {
            const relyItemArr = [];
            this.exam.forEach(item => {
              if (this.timeDependSetting.dependLocationKey.includes(item.locationKey)) {
                if (item.locationKey.startsWith('sbm_')) {
                  const dateTopic = item.optionList.find(val => this.timeDependSetting.sbmKey ===  val.locationKey)
                  dateTopic && relyItemArr.push(dateTopic)
                } else {
                  relyItemArr.push(item)
                }
              }
            })
            if (relyItemArr.length) {
              const isEmptyItem = relyItemArr.find(relyItem => !relyItem.value)
              if (isEmptyItem) {
                this.$toast(`请先作答【${isEmptyItem.name}】`);
                return false
              }

              const targetDate = this.$moment(val);
              let lassTimeVal = 0;

              switch (this.timeDependSetting.unit) {
                case 1: // 小时
                  lassTimeVal = this.timeDependSetting.lessTime * 60 * 60 * 1000;
                  break;
                case 2: // 天
                  lassTimeVal = this.timeDependSetting.lessTime * 24 * 60 * 60 * 1000;
                  break;
                case 3: // 周
                  lassTimeVal = this.timeDependSetting.lessTime * 7 * 24 * 60 * 60 * 1000;
                  break;
                case 4: // 月
                  lassTimeVal = this.timeDependSetting.lessTime * 30 * 24 * 60 * 60 * 1000;
                  break;
                default:
                  // 默认是小时
                  lassTimeVal = this.timeDependSetting.lessTime * 60 * 60 * 1000;
                  break;
              }
              
              for (let i = 0; i < relyItemArr.length; i ++) {
                const relyItem = relyItemArr[i]
                const relyItemDate = this.$moment(new Date(relyItem.value.split('-').join('/')))
                const duration = targetDate.diff(relyItemDate);
 
                if (this.timeDependSetting.less == 0) {
                  if (duration > (-lassTimeVal)) {
                    this.$toast(`所选时间不在范围内，请重新选择`);
                    return false
                  }
                }
                if (this.timeDependSetting.less == 1) {
                  if (duration < lassTimeVal) {
                    this.$toast(`所选时间不在范围内，请重新选择`);
                    return false
                  }
                }
              }
            }
          }
          return true
        },
        // val 拦截校验是否满足规则
        valVertify (val) {
          if (!this.timeDependSettingVertify(val)) return false
          
          if (this.timeLimitSetting && this.timeLimitSetting.timeRestrictions) {
            const targetDate = this.$moment(val);
            if (this.timeLimitSetting.less == 0) {
              const val = this.$moment().subtract(this.timeLimitSetting.lessTime, 'hours');
              if (targetDate.isAfter(val)) {
                this.$toast(`所选时间应小于当前时间${this.timeLimitSetting.lessTime}个小时`);
                return false
              }
            }
            if (this.timeLimitSetting.less == 1) {
              const val = this.$moment().add(this.timeLimitSetting.lessTime, 'hours');
              if (targetDate.isBefore(val)) {
                this.$toast(`所选时间应大于当前时间${this.timeLimitSetting.lessTime}个小时`);
                return false
              }
            }
          }
          
          return true
        },
        handleConfirm (val) {
            if (!this.valVertify(val)) return
            let dateFormat = 'YYYY-MM-DD'
            if (this.dateFormat === 'yyyy-MM-dd HH:mm') {
                dateFormat = 'YYYY-MM-DD HH:mm'
            }
            this.clearRelevanceTopicAnswer();

            if (this.dateFormat === 'yyyy') {
              this.modelValue = String(val)
            } else {
              this.modelValue = this.$moment(val).format(dateFormat)
            }

            this.handleCancel();
        },
        // 清空关联本题的答案；
        clearRelevanceTopicAnswer () {
          this.exam.forEach(item => {
            if (item.questionType == 41) {
              if (item.timeDependSetting && item.timeDependSetting.timeRestrictions) {
                if (item.timeDependSetting.dependLocationKey.includes(this.itemKey)) {
                  item.value = ''
                }
              }
            }
          })
        },
        handleCancel () {
            this.showYearPicker = false
            this.showPicker = false
        }
    }
}
</script>
