<template>
  <div class="bmi-container">
    <div class="bmi-progress-bar">
      <!-- 灰色部分，到达18.5 -->
      <div class="bmi-range grey" :style="{width: underweightBarWidth + '%'}">
        <div class="tag">18.5</div>
      </div>
      <!-- 绿色部分，18.5到24.99 -->
      <div class="bmi-range green" :style="{width: normalBarWidth + '%'}">
        <div class="tag1">正常</div>
      </div>
      <!-- 红色部分，24.99以上 -->
      <div class="bmi-range red" :style="{width: overweightBarWidth + '%'}">
        <div class="tag">24.99</div>
      </div>
      <div class="bmi-pointer" :style="{left: pointerLeft + '%', color: color}">
        <div>{{ bmiValue }}</div>
        <div>&#x25BC;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bmiValue: {
      type: Number,
      default: 21.7
    },
  },
  computed: {
    underweightBarWidth() {
      return ((18.5 - 12.1) / (31.3 - 12.1)) * 100;
    },
    normalBarWidth() {
      return ((24.99 - 18.5) / (31.3 - 12.1)) * 100;
    },
    overweightBarWidth() {
      return ((31.3 - 24.99) / (31.3 - 12.1)) * 100;
    },
    pointerLeft() {
      if (this.bmiValue >= 15.3 && this.bmiValue <= 28.2) {
        return ((this.bmiValue - 12.1) / (31.3 - 12.1)) * 100;
      } else if (this.bmiValue < 15.3) {
        return 15
      } else if(this.bmiValue > 28.2){
        return 85
      }
      return 21.7
    },
    color() {
      if (this.bmiValue < 18.5) {
        return 'gray'
      } else if (this.bmiValue >= 18.5 && this.bmiValue < 24.99) {
        return 'green'
      } else {
        return 'red'
      }
    }
  },
};
</script>

<style scoped>
.bmi-container {
  width: 100%;
  padding: 3.5vw;
  margin-top: 5vw;
  box-sizing: border-box;
}

.bmi-progress-bar {
  width: 100%;
  height: 0.5vw;
  display: flex;
  position: relative;
  background-color: #ddd; /* Fallback for areas not covered by bmi-range */
}

.bmi-range {
  height: 100%;
  transition: width 0.5s ease;
}

.bmi-range.grey {
  background-color: grey;
  text-align: right;
}

.bmi-range.green {
  background-color: green;
  text-align: center;
}

.bmi-range.red {
  background-color: red;
  text-align: left;
}
.tag {
  color: #9ea2a8;
  margin-top: 2vw;
  font-size: 3.8vw;
}
.tag1 {
  color: #9ea2a8;
  margin-top: 2vw;
  font-size: 3.6vw;
}
.bmi-pointer {
  position: absolute;
  top: -7vw; /* Upwards from center of bar; adjust as needed */
  color: black;
  user-select: none;
  font-size: 3.8vw; /* Adjust pointer size */
  transform: translateX(-50%); /* Center the pointer above the bmi value */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>