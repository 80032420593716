<template>
	<div class="i-surveys-form-sbm">
		<div v-for="(item, index) in options" :key="item.locationKey">
			<component
				:is="getComponentName(item.questionType)"
				v-model="item.value"
				v-bind="getComponentAttr(item, index, 'sbm')"
				:itemIndex="index"
				:mustAnswer="item.mustAnswer"
				:questionType="item.questionType"
			>
			</component>
		</div>
    <van-notice-bar
      style="margin-top: 20px"
      color="#F4CD13"
      background="#fff4e3"
      :text="`病例识别码：${getExamRecognizeCode || '系统自动填充无需填写'}`"
      :scrollable="false"
      wrapable
    />
	</div>
</template>

<script>
import FormInput from "./form-input.vue";
import FormRadio from "./form-radio.vue";
import FormDate from "./form-date.vue";
export default {
	components: { FormInput, FormRadio, FormDate },
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		options: {
			type: Array,
			default: () => [],
		},
    getComponentName: {
      type: Function,
      default: () => {}
    },
    getComponentAttr: {
      type: Function,
      default: () => {}
    }
	},
	data: () => ({}),
  computed: {
    // 获取识别码拼接成的识别码
    getExamRecognizeCode () {
      let [a, b, c] = ['', '', ''];
      this.options.forEach(v => {
        if (v.questionType == 21) {
          a = (v.value || '').toUpperCase()
        }
        // 单选题：默认第一个选项为男转化为“M”；第二个选项为女转为“F”
        if (v.questionType == 11) {
          if (v.value === 0) b = 'M';
          if (v.value === 1) b = 'F';
        }
        if (v.questionType == 41) {
          c = (v.value || '').split('-').join('')
        }
      })
      return a + b + c;
    }
  },
	methods: {
    // inputVal(val, item, index) {
    //   console.log(val)
    //   item.value = val

    // }
	},
};
</script>
