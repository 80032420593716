<template>
	<div class="i-surveys-form-input">
		<form-item v-bind="$attrs">
			<div
        class="input_control_box"
        :class="layoutType == 2 ? 'transverse': ''"
      >
        <div
          class="input_control"
          v-for="(val, idx) in options"
          :key="`input_${itemKey}_${idx}`"
        >
          <van-field
            :value="getModelValue[idx]"
            :placeholder="placeholder"
            :rules="needCount ? [{ validator: validatorCount, message: '填写数字总数不等于100' }] : rules"
            :name="itemKey"
            :readonly="isDisabled"
            @input="inputVal($event, idx)"
            :type="needCount ? 'number' : ''"
            @blur="handlerBlur($event, idx)"
          >
            <template #left-icon v-if="val.name">{{ val.name }}</template>
            <template #right-icon v-if="val.suffixName">{{ val.suffixName }}</template>
          </van-field>
        </div>
      </div>
		</form-item>
	</div>
</template>

<script>
import FormItem from "./form-item.vue";

export default {
	components: { FormItem },
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
    options: {
      type: Array,
      default: () => ([])
    },
		rules: {
			type: Array,
			default: () => [],
		},
		itemKey: {
			type: String,
			default: "",
		},
		needCount: {
			type: [Boolean, Number],
			default: 0,
		},
    exam: {
      type: Array,
      default: () => ([])
    },
    // 1是纵向 2是横向
    layoutType: {
      type: [Number,String],
      default: 1
    },
    settingNumber: {
      type: Object,
      default: () => ({})
    },
    settingContent: {
      type: Object,
      default: () => ({})
    }
	},
  data: () => ({
    separator: '@%_@%',
    // 横向时候，每行展示的数量
    layoutTypeCount: 5
  }),
	computed: {
    getModelValue () {
      return this.value.split(this.separator);
    },
		isDisabled() {
			return this.$route.query.formType === "preview";
		},
	},
  methods: {
    valVertifyValLength (val, option) {
      if (this.settingContent) {
        if (typeof this.settingContent.min === 'number' && val.length < this.settingContent.min) {
          this.$toast(`内容最少需要输入${this.settingContent.min}个字符`);
          return false
        }
        if (typeof this.settingContent.max === 'number' && val.length > this.settingContent.max) {
          this.$toast(`内容最多输入${this.settingContent.max}个字符`);
          return false
        }
      }

      if (option.settingContent) {
        if (typeof option.settingContent.min === 'number' && val.length < option.settingContent.min) {
          this.$toast(`内容最少需要输入${option.settingContent.min}个字符`);
          return false
        }
        if (typeof option.settingContent.max === 'number' && val.length > option.settingContent.max) {
          this.$toast(`内容最多输入${option.settingContent.max}个字符`);
          return false
        }
      }

      return true
    },
    valVertifyNumber (val, option) {
      if (!val && val !== 0) return true
      if (val === '-') return true

      if (!(/^-?\d+(\.\d+)?$/.test(val))) {
        this.$toast("内容仅支持输入数字格式");
        return false
      }

      if (this.settingContent) {
        if (typeof this.settingContent.numberMin === 'number' && (+val) < this.settingContent.numberMin) {
          this.$toast(`请输入${this.settingContent.numberMin}~${this.settingContent.numberMax}以内的数字`);
          return false
        }
        if (typeof this.settingContent.numberMax === 'number' && (+val) > this.settingContent.numberMax) {
          this.$toast(`请输入${this.settingContent.numberMin}~${this.settingContent.numberMax}以内的数字`);
          return false
        }
      }

      if (option.settingContent) {
        if (typeof option.settingContent.numberMin === 'number' && (+val) < option.settingContent.numberMin) {
          this.$toast(`请输入${option.settingContent.numberMin}~${option.settingContent.numberMax}以内的数字`);
          return false
        }
        if (typeof option.settingContent.numberMax === 'number' && (+val) > option.settingContent.numberMax) {
          this.$toast(`请输入${option.settingContent.numberMin}~${option.settingContent.numberMax}以内的数字`);
          return false
        }
      }
      return true
    },
    valVertifyLetter (val) {
      if (!val && val !== 0) return true
      if (!(/^[A-Za-z\s]+$/.test(val))) {
        this.$toast("内容仅支持输入字母格式");
        return false
      }
      return true
    },
    valVertifyCharacter (val) {
      if (!val && val !== 0) return true
      if (!(/^[\u4e00-\u9fa5]+$/.test(val))) {
        this.$toast("内容仅支持输入文字格式");
        return false
      }
      
      return true
    },
    // 数字类型限制，和上一题做比较
    checkTimeRestrictions (val) {
      if (!val && val !== 0) return true
      const relyItem = this.exam.find(item => item.locationKey === this.settingNumber.dependLocationKey);
      if (relyItem) {
        if (!relyItem.value) {
          this.$toast(`请先作答【${relyItem.name}】`);
          return false
        }
        const relyItemArr = relyItem.value.split('@%_@%').map(item => this.stringToNumberAndValidate(item))
        const value = this.stringToNumberAndValidate(val);

        // 0 是 小于 1是大于
        if (this.settingNumber.less == 0) {
          const relyItemValue = Math.min(...relyItemArr);
          if ((value + this.settingNumber.number) > relyItemValue ) {
            this.$toast(`答案应至少比【${relyItem.name}】小${ this.settingNumber.number }`);
            return false
          }
        }
        if (this.settingNumber.less == 1) {
          const relyItemValue = Math.max(...relyItemArr);
          if ((this.settingNumber.number + relyItemValue) > value) {
            this.$toast(`答案应至少比【${relyItem.name}】大${ this.settingNumber.number }`);
            return false
          }
        }
      } 
      return true
    },
    // 输入的内容的格式根据规则产生校验
    valBlurVertify (val, index) {
      let status = true;
      const option = this.options[index];
      if (status) {
        if (this.settingContent) {
          if (status && this.settingContent.contentRestrictions) {
            switch (this.settingContent.contentType) {
              case 'number':
                status = this.valVertifyNumber(val, option);
                break;
              case 'letter':
                status = this.valVertifyLetter(val);
                break;
              case 'character':
                status =  this.valVertifyCharacter(val);
                break;
            }
          }
        }
        if (option.settingContent) {
          if (status && option.settingContent.contentRestrictions) {
            switch (option.settingContent.contentType) {
              case 'number':
                status = this.valVertifyNumber(val, option);
                break;
              case 'letter':
                status = this.valVertifyLetter(val);
                break;
              case 'character':
                status =  this.valVertifyCharacter(val);
                break;
            }
          }
        }
      }
      if (status) {
        status = this.valVertifyValLength(val, option)
      }
      if (status && this.settingNumber) {
        if (status && this.settingNumber.timeRestrictions) {
          status = this.checkTimeRestrictions(val)
        }
      }

      return status
    },
    inputValFormat (val, config) {
      if (config.settingContent) {
        // 字母展示
        if (config.settingContent.contentType === 'letter') {
          // 显示大写
          if (config.settingContent.isCapitalized == 1) {
            return val.toUpperCase()
          }
        }
      }
      return val
    },
    inputVal (value, valIndex) {
      let val = value;
      if (this.isDisabled) return
      const optionItem = this.options[valIndex]
      val = this.inputValFormat(val, optionItem)
      const newVal = JSON.parse(JSON.stringify(this.getModelValue))
      newVal[valIndex] = val.trim()
      this.clearRelevanceTopicAnswer();
      this.$emit('input', newVal.join(this.separator))
    },
    // 清空关联本题的答案；
    clearRelevanceTopicAnswer () {
      this.exam.forEach(item => {
        if (item.questionType == 21) {
          if (item.settingNumber && item.settingNumber.timeRestrictions) {
            if (item.settingNumber.dependLocationKey === this.itemKey) {
              if (item.value) {
                item.value = ''
              }
            }
          }
        }
      })
    },
    validatorCount() {
      let counts = this.value.split('@%_@%')
      return counts.reduce((acc, currentValue) => {
        let number = Number(currentValue);
        return acc + (isNaN(number) ? 0 : number);
      }, 0) == 100
    },
    handlerBlur(event, index) {
      const inputValue = this.getModelValue[index]
      if (!this.valBlurVertify(inputValue, index)) {
        this.inputVal('', index);
        return
      }
      this.$emit('sumBlur')
    },
    stringToNumberAndValidate(str) {
      const num = parseFloat(str);
      return !isNaN(num) ? num : 0;
    }
  }
};
</script>
