<template>
	<div class="i-surveys-form-input">
		<form-item v-bind="$attrs">
			<van-field
				v-model="modelValue"
        type="textarea"
				:placeholder="placeholder"
				:rules="rules"
				:name="itemKey"
				:readonly="isDisabled"
        :maxlength="maxLength"
        show-word-limit
			/>
		</form-item>
	</div>
</template>

<script>
import FormItem from "./form-item.vue";

export default {
	components: { FormItem },
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		rules: {
			type: Array,
			default: () => [],
		},
		itemKey: {
			type: String,
			default: "",
		},
    maxLength: {
      type: [String, Number],
			default: "9999"
    }
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val.trim());
			},
		},
		isDisabled() {
			return this.$route.query.formType === "preview";
		},
	},
};
</script>
