<template>
    <div
      class="i-surveys-form-item"
      v-if="isShowTopic"
    >
        <div class="item-label" v-if="label">
            <span v-if="mustAnswer" class="red">*</span>
            <span class="item-label__inner" v-html="labelText"></span>
            <span class="file_code" v-if="fileType && fileList.length" @click="clickShowFile()">附件</span>
            <span v-if="questionType==12" class="tips">(可多选)</span>
        </div>
        <div class="item-error-message" v-if="showErrorMessage">
          <p
            class="item-error-message__inner"
            v-for="item in (rejectSetting.becauseList || [])"
            :key="item"
          >{{ item }}</p>
        </div>
        <div class="item-main">
            <slot></slot>
        </div>

        <van-popup
          v-model="fileVisible"
          closeable
          round
          close-on-popstate
        >
          <div class="preview_video">
            <van-swipe style="height: 100%; width: 100%;">
              <van-swipe-item
                v-for="item in fileVideoList"
                :key="item"
              >
                <video class="video__inner" :src="item" controls></video>
              </van-swipe-item>
            </van-swipe>
          </div>
        </van-popup>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
    props: {
        label: String,
        index: [Number, String],
        mustAnswer:[Number],
        questionType:[Number,String],
        endTopicIndex: [Number, String],
        auditStatus: [Number,String],
        isShowFailTopic: Boolean,
        rejectSetting: {
          type: Object,
          default: () => ({})
        },
        fileType: String,
        fileList: {
          type: Array,
          default: () => ([])
        },
    },
    data : ()=> ({
      fileVisible: false,
      fileVideoList: [],
    }),
    computed: {
        labelText () {
          return this.label
        },
        isShowTopic () {
          if (this.index > this.endTopicIndex) return false
          if (this.isShowFailTopic) return this.showErrorMessage
          return true
        },
        showErrorMessage () {
          let status = false;
          if (this.auditStatus == 3) {
            if (this.rejectSetting) {
              if (this.rejectSetting.rejectRestrictions == 1) {
                if (this.rejectSetting.becauseList && this.rejectSetting.becauseList.length) {
                  status = true
                }
              }
            }
          }
          return status
        }
    },
    methods: {
      isBr (val) {
        return val.includes('\n')
      },
      // 点击查看附件
      clickShowFile (options) {
        if (options) {
          if (options.type === 'image') {
            ImagePreview(options.list);
          }
          if (options.type === 'video') {
            this.fileVideoList = options.list;
            this.fileVisible = true
          }
        } else {
          if (this.fileType === 'image') {
            ImagePreview(this.fileList);
          }
          if (this.fileType === 'video') {
            this.fileVideoList = this.fileList;
            this.fileVisible = true
          }
        }
      }
    }
}

</script>
<style scoped>
.red{
    color:red;
}
.tips{
    font-size: 14px;
    color:#666;
}
.file_code {
  font-family: PingFang SC;
  font-weight: normal;
  line-height: normal;
  text-decoration: underline;
  color: #3B82F6;
  padding: 0 4px;
}
.preview_video_mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .5);
}
.preview_video,
.video__inner {
  width: 80vw;
  height: 300px;
}
</style>
