<template>
  <div class="i-surveys-form-input">
    <form-item v-bind="$attrs">
      <div class="input_control" v-for="(val, idx) in options" :key="`input_${itemKey}_${idx}`">
        <div class="label">{{ val.label }}</div>
        <van-field :value="getModelValue[idx]" :placeholder="placeholder" :rules="needCount ? [{ validator: validatorCount, message: '填写数字总数不等于100' }] : rules" :name="itemKey" :readonly="isDisabled" @input="inputVal($event, idx)" type="number">
          <template #left-icon v-if="val.name">{{ val.name }}</template>
          <template #right-icon v-if="val.suffixName">{{ val.suffixName }}</template>
        </van-field>
      </div>
    </form-item>
    <bmi-icon :bmiValue="bmiValue"></bmi-icon>
  </div>
</template>

<script>
import FormItem from "./form-item.vue";
import BmiIcon from "./bmi-icon.vue"
export default {
  components: { FormItem, BmiIcon },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => ([])
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "",
    },
    needCount: {
      type: [Boolean, Number],
      default: 0,
    }
  },
  data: () => ({
    separator: '@%_@%'
  }),
  computed: {
    getModelValue() {
      return this.value.split(this.separator);
    },
    isDisabled() {
      return this.$route.query.formType === "preview";
    },
    bmiValue() {
        console.log(this.value)
        let counts = this.value.split('@%_@%')
        console.log(counts)
        if(counts.length > 1 && counts.indexOf('') == -1) {
            console.log(counts[0] / (counts[1] * counts[1]))
            return (counts[0] / (counts[1] * counts[1])).toFixed(1)
        }   
        return 21.7
    }
  },
  methods: {
    inputVal(val, valIndex) {
      if (this.isDisabled) return
      const newVal = JSON.parse(JSON.stringify(this.getModelValue))
      newVal[valIndex] = val.trim()
      this.$emit('input', newVal.join(this.separator))
    },
    validatorCount() {
      console.log('value', this.value)
      let counts = this.value.split('@%_@%')
      return counts.reduce((acc, currentValue) => {
        let number = Number(currentValue);
        return acc + (isNaN(number) ? 0 : number);
      }, 0) == 100
    }
  }
};
</script>
<style scoped>
    .input_control {
        display: flex;
    }
    .label{
        font-size: 3.8vw;
        white-space: nowrap;
        margin-top: 2.5vw;
        margin-right: 1vw;
    }
</style>
