<template>
	<div class="i-surveys-form-upload">
		<form-item v-bind="$attrs">
			<van-field :name="itemKey" :rules="rules">
				<template #input>
					<base-upload
						v-model="modelValue"
						:limit="limit"
						:disabled="isDisabled"
            :accept="accept"
						@success="uploadSuccess"
					/>
				</template>
			</van-field>
		</form-item>
	</div>
</template>

<script>
import FormItem from "./form-item.vue";

export default {
	components: {
		FormItem,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
		rules: {
			type: Array,
			default: () => [],
		},
		itemKey: {
			type: String,
			default: "",
		},
		limit: {
			type: [Number, String],
			default: 999,
		},
		ocr: {
			type: String,
			default: "",
		},
    accept: {
			type: String,
			default: "image/*",
		},
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		isDisabled() {
			return this.$route.query.formType === "preview";
		}
	},
	methods: {
		uploadSuccess(list, file) {
			if (this.ocr && file.url) {
				this.$api.surveys
					.checkOcr({
						text: this.ocr,
						url: file.url,
					})
					.then((res) => {
						if (!res.code) {
							const index = list.findIndex(
								(item) => item.url === file.url
							);
							if (!res.data) {
								this.$dialog
									.confirm({
										title: "提示",
										message: `您上传的图片未包含${this.ocr}名称，请确认是否上传？`,
										showCancelButton: true,
										confirmButtonText: "确认上传",
										cancelButtonText: "取消上传",
									})
									.then(() => {
										list[index]["ocrResult"] = 0;
									})
									.catch(() => {
										if (index > -1) {
											list.splice(index, 1);
											this.modelValue = list;
										}
									});
							} else {
								list[index]["ocrResult"] = 1;
							}
						}
					});
			}
		},
	},
};
</script>
