<template>
    <div class="i-surveys-form-checkbox">
        <form-item v-bind="$attrs" ref="formItem">
            <van-field :name="itemKey" :rules="rules">
                <template #input>
                    <van-checkbox-group v-model="modelValue" :disabled="isDisabled" >
                      <draggable
                        :list="options"
                        @update="draggableUpdate"
                        @start="drag=true"
                        @end="drag=false"
                        :disabled="isDisabled"
                        handle=".sort_icon"
                        draggable=".checkbox_item_active"
                        animation="300"
                      >
                        <div
                          class="checkbox_item"
                          v-for="(item, index) in options"
                          :key="`checkbox_${new Date().getTime()}_${index}`"
                          :class="modelValue.includes(index) ? 'checkbox_item_active' : ''"
                        >
                          <div class="checkbox_item__inner">
                            <van-checkbox :name="index" :disabeld="isItemDisabled(index)">
                              <div class="checkbox_sort">
                                <span
                                  class="sort_main"
                                  v-if="isManualSort"
                                  :class="modelValue.includes(index) ? 'active' : ''"
                                >{{ index + 1 }}</span>
                                <span>{{ item.name }}{{ item.append || '' }} <span class="file_code" v-if="optionType && item.fileUrl" @click.stop="clickShowFile(item)">附件</span></span>
                              </div>
                            </van-checkbox>
                            <img v-if="isManualSort" class="sort_icon" src="@/img/icon-checkbox-sort.png" alt="">
                          </div>
                          <div v-if="isShowOtherContent(item, index, 'input')" class="i-surveys-form-input" style="margin: 10px 0;">
                            <van-field :value="item.ext.inputValue" @input="inputVal($event, index)" @focus="inputFocus($event, item.ext.inputValue)" @blur="inputBlur($event, index)" :disabled="isDisabled" :rules="item.ext.inputMustAnswer && modelValue.includes(index)? [{required: true,message:'必填项不能为空',trigger: 'onBlur',}]: []" placeholder="请输入" style="padding: 9px 14px;">
                              <template #extra>{{item.ext.suffixName}}</template>
                              <template #left-icon>{{item.ext.prefixName}}</template>
                            </van-field>
                          </div>
                          <div v-if="isShowOtherContent(item, index, 'img')">
                            <form-upload v-model="item.ext.fileUrl" :disabled="isDisabled" :rules="item.ext.imageMustAnswer && modelValue.includes(index)? [{required: true,message:'必填项不能为空',trigger: 'onBlur',}]: []" />
                          </div>
                        </div>
                      </draggable>
                    </van-checkbox-group>
                </template>
            </van-field>
        </form-item>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
import FormItem from './form-item.vue'
import FormUpload from './form-upload.vue'

export default {
    components: { FormItem, draggable, FormUpload },
    data: () => ({
      inputValue: ''
    }),
    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        options: {
            type: Array,
            default: () => ([])
        },
        rules: {
            type: Array,
            default: () => ([])
        },
        itemKey: {
            type: String,
            default: ''
        },
        itemIndex:{
            type: [String, Number],
            default: ''
        },
        isManualSort: {
          type: [String, Number],
          default: ''
        },
        optionType: String,
    },
    computed: {
        modelValue: {
            get () {
                return this.value
            },
            set (val) {
              let _v = this.exclusiveFilter(val);

              // 排序规则
              if (this.isManualSort) {
                this.updateOptionsList(_v)
              } else {
                // 默认
                this.$emit('input', _v)
                this.$emit('sumBlur')
              }
            }
        },
        isDisabled () {
            return this.$route.query.formType === 'preview'
        }
    },
    mounted () {
      if (!(this.modelValue || []).length) {
        const defaultVal = [];
        this.options.forEach((item, index) => {
          item.defaultFlag && defaultVal.push(index);
        })
        this.modelValue = defaultVal;
      }
    },
    methods: {
      valVertifyValLength (val, option) {
        if (this.settingContent) {
          if (typeof this.settingContent.min === 'number' && val.length < this.settingContent.min) {
            this.$toast(`内容最少需要输入${this.settingContent.min}个字符`);
            return false
          }
          if (typeof this.settingContent.max === 'number' && val.length > this.settingContent.max) {
            this.$toast(`内容最多输入${this.settingContent.max}个字符`);
            return false
          }
        }

        if (option.settingContent) {
          if (typeof option.settingContent.min === 'number' && val.length < option.settingContent.min) {
            this.$toast(`内容最少需要输入${option.settingContent.min}个字符`);
            return false
          }
          if (typeof option.settingContent.max === 'number' && val.length > option.settingContent.max) {
            this.$toast(`内容最多输入${option.settingContent.max}个字符`);
            return false
          }
        }

        return true
      },
      valVertifyNumber (val, option) {
        if (!val && val !== 0) return true
        if (val === '-') return true

        if (!(/^-?\d+(\.\d+)?$/.test(val))) {
          this.$toast("内容仅支持输入数字格式");
          return false
        }

        if (this.settingContent) {
          if (typeof this.settingContent.numberMin === 'number' && (+val) < this.settingContent.numberMin) {
            this.$toast(`请输入${this.settingContent.numberMin}~${this.settingContent.numberMax}以内的数字`);
            return false
          }
          if (typeof this.settingContent.numberMax === 'number' && (+val) > this.settingContent.numberMax) {
            this.$toast(`请输入${this.settingContent.numberMin}~${this.settingContent.numberMax}以内的数字`);
            return false
          }
        }

        if (option.settingContent) {
          if (typeof option.settingContent.numberMin === 'number' && (+val) < option.settingContent.numberMin) {
            this.$toast(`请输入${option.settingContent.numberMin}~${option.settingContent.numberMax}以内的数字`);
            return false
          }
          if (typeof option.settingContent.numberMax === 'number' && (+val) > option.settingContent.numberMax) {
            this.$toast(`请输入${option.settingContent.numberMin}~${option.settingContent.numberMax}以内的数字`);
            return false
          }
        }
        return true
      },
      valVertifyLetter (val) {
        if (!val && val !== 0) return true
        if (!(/^[A-Za-z\s]+$/.test(val))) {
          this.$toast("内容仅支持输入字母格式");
          return false
        }
        return true
      },
      valVertifyCharacter (val) {
        if (!val && val !== 0) return true
        if (!(/^[\u4e00-\u9fa5]+$/.test(val))) {
          this.$toast("内容仅支持输入文字格式");
          return false
        }
        
        return true
      },
      // 输入的内容的格式根据规则产生校验
      valBlurVertify (val, index) {
        let status = true;
        const option = this.options[index];
        if (status) {
          if (option.ext) {
            if (status && option.ext.contentRestrictions) {
              switch (option.ext.contentType) {
                case 'number':
                  status = this.valVertifyNumber(val, option);
                  break;
                case 'letter':
                  status = this.valVertifyLetter(val);
                  break;
                case 'character':
                  status =  this.valVertifyCharacter(val);
                  break;
              }
            }
          }
        }

        return status
      },
      isShowOtherContent (item, index, type) {
        if (!this.modelValue.includes(index)) return false
        if (!item.ext) return false;
        if (!item.ext.type) return false;
        return item.ext.type.includes(type)
      },
      inputFocus (event, val) {
        this.inputValue = val;
      },
      inputBlur (event, index) {
        if (!this.valBlurVertify(this.inputValue, index)) {
          this.inputVal('');
          return
        }
        this.$set(this.options[index].ext, 'inputValue', this.inputValue)
        this.inputValue = ''
      },
      inputVal (val) {
        this.inputValue = val;
      },
      isItemDisabled (index) {
        if (!this.modelValue || !this.modelValue.length) return false
        const ci = this.options[index];
        const fi = this.options[this.modelValue[0]];
        if (!ci.defaultExclusive && !fi.defaultExclusive) return false;
        return ci.defaultExclusive == fi.defaultExclusive ? false : true;
      },
      // 互斥规则
      exclusiveFilter (val) {
        const _v = [];
        if (!val || !val.length) return _v;

        const firstDefaultExclusive = this.options[val[0]].defaultExclusive;
        val.forEach((index) => {
          const item = this.options[index];
          if (
            (!item.defaultExclusive && !firstDefaultExclusive) ||
            (item.defaultExclusive == firstDefaultExclusive)
          ) {
            _v.push(index);
          }
        })

        return _v;
      },
      draggableUpdate ({ newIndex, oldIndex }) {
        // const answerList = JSON.parse(JSON.stringify(this.modelValue))
        // console.log(newIndex, oldIndex)
        // answerList[newIndex] = this.modelValue[oldIndex]
        // answerList[oldIndex] = this.modelValue[newIndex]
      },
      updateOptionsList (answerList) {
        const oldOptions = JSON.parse(JSON.stringify(this.options))
        const newOptions = [];
        const newAnswer = [];
        answerList.forEach((item, index) => {
          newOptions.push(oldOptions[item]);
          newAnswer.push(index);
        })

        if (newOptions.length < oldOptions.length) {
          const newOptionsKeys = newOptions.map(item => item.key);
          const otherOptions = oldOptions.filter(item => !newOptionsKeys.includes(item.key))
          newOptions.push(...otherOptions)
        }
        this.$emit('updateOptions', newOptions, this.itemIndex)
        this.$emit('input', newAnswer);
        this.$emit('sumBlur')
      },
      // 点击查看附件
      clickShowFile (item) {
        this.$refs.formItem.clickShowFile({
          type: this.optionType, 
          list: [item.fileUrl]
        })
      }
    }
}
</script>
