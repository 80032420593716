<template>
	<div class="i-surveys-form-input" v-if="index <= endTopicIndex">
    <div style="height: 0; overflow: hidden;"><form-item ref="formItem"></form-item></div>
		<h2 class="item_sub_title"><span v-html="label"></span><span class="file_code" v-if="fileInfo.needFile" @click.stop="clickShowFile">附件</span></h2>
	</div>
</template>

<script>
import FormItem from "./form-item.vue";
export default {
  components: { FormItem },
	props: {
		value: {
			type: [String, Number],
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		rules: {
			type: Array,
			default: () => [],
		},
		itemKey: {
			type: String,
			default: "",
		},
    fileInfo: {
      type: Object,
      default: () => {}
    },
		label: {
			type: String,
			default: "",
		},
		index: [Number, String],
		endTopicIndex: [Number, String],
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
		isDisabled() {
			return this.$route.query.formType === "preview";
		},
	},
  methods: {
    // 点击查看附件
    clickShowFile () {
      this.$refs.formItem.clickShowFile({
        type: this.fileInfo.fileType, 
        list: this.fileInfo.fileList
      })
    }
  }
};
</script>
