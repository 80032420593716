<template>
  <div class="i-surveys-form-input">
    <form-item v-bind="$attrs">
      <div class="input_control" v-for="(val, idx) in options" :key="`input_${itemKey}_${idx}`">
        <van-field :value="value" placeholder="自动计算值" :rules="rules" :name="itemKey" readonly>
          <template #left-icon v-if="val.name">{{ val.name }}</template>
          <template #right-icon v-if="val.suffixName">{{ val.suffixName }}</template>
        </van-field>
      </div>
    </form-item>
  </div>
</template>

<script>
import FormItem from "./form-item.vue";

export default {
  components: { FormItem },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => ([])
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: "",
    },
    needCount: {
      type: [Boolean, Number],
      default: 0,
    }
  },
  data: () => ({
    separator: '@%_@%',
  }),
  computed: {
    getModelValue() {
      console.log('11111111',this.value)
      return this.value;
    },
    isDisabled() {
      return this.$route.query.formType === "preview";
    },
  },
  methods: {
    inputVal(val, valIndex) {
      if (this.isDisabled) return
      const newVal = JSON.parse(JSON.stringify(this.getModelValue))
      newVal[valIndex] = val.trim()
      this.$emit('input', newVal.join(this.separator))
    },
    validatorCount() {
      console.log('value', this.value)
      let counts = this.value.split('@%_@%')
      return counts.reduce((acc, currentValue) => {
        let number = Number(currentValue);
        return acc + (isNaN(number) ? 0 : number);
      }, 0) == 100
    }
  }
};
</script>
